// export default function combineDateAndTime(
//   date: Date,
//   time: Date
// ): Date | null {
//   if (!date || !time) {
//     return null;
//   }
//   const timeString = time.getHours() + ':' + time.getMinutes() + ':00';
//   const year = date.getFullYear();
//   const month = date.getMonth() + 1; // Jan is 0, dec is 11
//   const day = date.getDate();
//   const dateString = '' + year + '-' + month + '-' + day;
//   return new Date(dateString + ' ' + timeString);
// }

export default function combineDateAndTime(
  date: Date,
  time: Date
): Date | null {
  if (!date || !time) {
    return null;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(time.getHours()).padStart(2, '0');
  const minutes = String(time.getMinutes()).padStart(2, '0');
  const seconds = '00';

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  const combinedDate = new Date(isoString);

  return isNaN(combinedDate.getTime()) ? null : combinedDate;
}
