import { EquipmentCategory, EquipmentItem } from 'api/equipmentApi';
import { Inspection } from 'api/inspectionsApi';
import { User } from 'api/userApi';
import Axios from 'axios';
import { z } from 'zod';

export interface InstalledEquipment {
  id: string;
  organizationId: number;
  jobId: string;
  roomId: string;
  inspectionId: string;
  categoryId: string;
  equipmentId: string;
  itemState: boolean;
  installedByUserId: string;
  pickedUpByUserId: string;
  installedSiteVisit: string;
  pickedUpSiteVisit: string;
  installedTime: string;
  pickupTime: string;
  createdOn: string;
  createdByUserId: string;
  updatedOn: string;
  updatedByUserId: string;
  equipmentItem: EquipmentItem;
  category: EquipmentCategory;
  inspection: Inspection;
  installedByUser: User;
  pickedUpByUser: User;
}

export async function installEquipment({
  roomId,
  inspectionId,
  jobId,
  selectedEquipmentItems,
  installedSiteVisit,
  installedTime,
  pickedUpSiteVisit,
  pickupTime,
}: {
  roomId: string;
  inspectionId: string;
  jobId: string;
  selectedEquipmentItems: EquipmentItem[];
  installedSiteVisit: string;
  installedTime: string;
  pickedUpSiteVisit: string;
  pickupTime: string;
}) {
  let data = {
    selectedEquipmentItems,
    installedSiteVisit,
    installedTime,
    pickedUpSiteVisit,
    pickupTime,
  };
  const res = await Axios.post(
    `/api/v1/inspections/equipment-installation/${jobId}/${inspectionId}/${roomId}/install`,
    { ...data }
  );
  return res.data;
}

export async function pickupEquipment({
  actionId,
  roomId,
  inspectionId,
  jobId,
  pickedUpSiteVisit,
  pickupTime,
}: {
  actionId: string;
  roomId: string;
  inspectionId: string;
  jobId: string;
  pickedUpSiteVisit: string;
  pickupTime: string;
}) {
  let data = {
    pickedUpSiteVisit,
    pickupTime,
  };
  const res = await Axios.put(
    `/api/v1/inspections/equipment-installation/${jobId}/${inspectionId}/${roomId}/pickup/${actionId}`,
    { ...data }
  );
  return res.data;
}

export async function getEquipmentInstalledInRoom(
  jobId: string,
  inspectionId: string,
  roomId: string,
  inspectionDate: string
): Promise<InstalledEquipment[]> {
  const res = await Axios.get(
    `/api/v1/inspections/equipment-installation/${jobId}/${inspectionId}/${roomId}/${inspectionDate}`
  );

  return res.data;
}

const EquipmentInstallationResponseSchema = z.object({
  equipmentId: z.string(),
  categoryId: z.string(),
  installationId: z.string(),
  installedStatus: z.string(),
  rank: z.number(),
  model: z.string(),
  referenceId: z.string(),
  brand: z.string(),
  make: z.string(),
  jobId: z.string(),
  roomId: z.string(),
  inspectionId: z.string(),
  organizationId: z.number(),
  itemState: z.boolean(),
  installedSiteVisit: z.string(),
  installedTime: z.string(),
  pickedUpSiteVisit: z.string(),
  pickupTime: z.string(),
  jobNumber: z.number(),
  roomName: z.string(),
  orgIdentifier: z.string(),
});

type EquipmentInstallationResponse = z.infer<
  typeof EquipmentInstallationResponseSchema
>;

export async function getCategoryById(
  categoryId: string,
  inspectionDate: string,
  installationTime: string
): Promise<EquipmentInstallationResponse[]> {
  if (categoryId) {
    const res = await Axios.get(
      `/api/v1/inspections/equipment-installation/${categoryId}/${inspectionDate}/${installationTime}`
    );
    return res.data;
  }
}

export async function deleteEquipmentInstallation(installationId: string) {
  const res = await Axios.delete(
    `/api/v1/inspections/equipment-installation/delete/${installationId}`
  );
  return res.data;
}

export async function listAvailableInspections(
  jobId: string,
  inspectionDate: string,
  maximumPickupDate: string
): Promise<Inspection[]> {
  if (maximumPickupDate !== null && inspectionDate !== null) {
    const res = await Axios.get(
      `/api/v1/inspections/equipment-installation/available-inspections/get-available/${jobId}/${inspectionDate}/${maximumPickupDate}`
    );
    return res.data;
  }
}

export async function validateJobsOpenEquipmentInstallations(jobId: string) {
  const res = await Axios.get(
    `/api/v1/inspections/equipment-installation/validate-job-installed-equipment/${jobId}`
  );
  return res.data;
}
